import { Fragment } from "react"
import CardOrdeItem from "overrides/theme/entel/base/components/Core/Order/CardOrder/CardOrdeItem"
import { orderListStyle } from "overrides/theme/entel/base/components/Core/Order/styles/OrderList.style"

const OrderList = ({
  ordersGroup,
  showStatus,
  currentDisplayList,
  handleClickCheckOrder,
  canSelectMultiple,
  conditionChecked,
  ordersSGChecked,
  statusSG,
  prepicking_mode,
  sgs_checked_to_prepicking,
  handle_sg_to_prepicking,
}: any) => {
  const columns_default = [
    "ID EOC",
    "TIPO DE ENTREGA",
    "COURIER",
    "NOMBRE CLIENTE",
    "CREACION",
    "SLOT DELIVERY",
    "COMUNA DESTINO",
    "FECHA EST. ENTREGA",
    "BODEGA/TIENDA",
  ]

  const columns_prepicking = ["", "ID EOC", "Producto 1", "Producto 2", "Producto 3", "Producto 4"]

  const columns = prepicking_mode ? columns_prepicking : columns_default

  return (
    <Fragment>
      {ordersGroup && (
        <>
          {Object.keys(ordersGroup).map((key, index) => (
            <div className="order-list__content-group" key={key}>
              <div className="order-list__group-item">
                <p>{key}</p>
              </div>
              {currentDisplayList === "list" && prepicking_mode && index === 0 ? (
                <div className="order-list__lv__header-titles-container--prepicking">
                  <div className="order-list__lv__content-titles--prepicking">
                    {columns.map((col: string, i: number) => (
                      <p className="order-list__lv__item-title--prepicking" key={i}>
                        {col}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                currentDisplayList === "list" &&
                index === 0 && (
                  <div className="order-list__lv__header-titles-container">
                    <div
                      className="order-list__lv__content-titles"
                      style={{
                        paddingLeft: canSelectMultiple ? "30px" : prepicking_mode ? "100px" : "",
                        // gridTemplateColumns: prepicking_mode ? "repeat(8, 1fr)" : "repeat(10, 1fr)",
                      }}
                    >
                      <p className="order-list__lv__item-title"></p>
                      {/* <p className="order-list__lv__item-title">PORTABILIDAD</p> */}
                      {columns.map((col: string, i: number) => (
                        <p className="order-list__lv__item-title" key={i}>
                          {col}
                        </p>
                      ))}
                    </div>
                  </div>
                )
              )}
              <div
                className={
                  currentDisplayList === "grid"
                    ? "order-list__cont-list order-list__gv__cont-list"
                    : "order-list__cont-list"
                }
              >
                {ordersGroup[key].map((order: any) => (
                  <CardOrdeItem
                    key={`${order.orderId}-${order?.id}`}
                    {...order}
                    canSelect={canSelectMultiple && (!conditionChecked || conditionChecked(order))}
                    handleClickSelectOrder={handleClickCheckOrder}
                    isCheckedOrder={ordersSGChecked?.includes(order.id)}
                    statusSG={statusSG}
                    showStatus={showStatus}
                    currentDisplayList={currentDisplayList}
                    prepicking_mode={prepicking_mode}
                    columns={columns}
                    handle_sg_to_prepicking={handle_sg_to_prepicking}
                    is_checked_to_prepick={sgs_checked_to_prepicking?.includes(order.salesChannelId)}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      )}
      <style jsx>{orderListStyle}</style>
    </Fragment>
  )
}

export default OrderList
