import { Fragment } from "react/jsx-runtime"
import { alertDetailOptionStatusStyle } from "overrides/theme/entel/base/components/OrderDetail/AlertDetailOptionStatus/AlertDetailOptionStatus.style"
import { PiWarningFill } from "react-icons/pi"
import cn from "classnames"

interface IAlertDetailOptionStatus {
  status: any
}

const AlertDetailOptionStatus = ({ status }: IAlertDetailOptionStatus) => {
  const getTextByStatus = () => {
    switch (status) {
      case "PICKING_COMPLETED_PENDING_PACKAGE":
        return {
          type: "warning",
          value:
            "Recuerda imprimir los documentos (con el botón de actualizar información si aún no han llegado) y luego confirmar con el botón de empacado.",
        }
      case "PACKED_AND_READY_FOR_PICKUP":
        return {
          type: "warning",
          value:
            "Recuerda la impresión de documentos y luego confirmar la entrega al courier cuando despachen los envíos!",
        }
      case "DELIVERED_TO_COURIER":
        return {
          type: "danger",
          value:
            "Si ya el courier retiró el pedido, no es necesario realizar más acciones a excepción de que hagan una devolución.",
        }
      default:
        return null
    }
  }

  const alert = getTextByStatus()

  if (!alert) return null
  return (
    <Fragment>
      <div className={cn("alert_detail_opt_status__main", {
        "alert_detail_opt": Boolean(alert.type === "danger"),
      })}>
        <span className="icon">
          <PiWarningFill />
        </span>
        <p>{alert.value}</p>
      </div>
      <style jsx>{alertDetailOptionStatusStyle}</style>
    </Fragment>
  )
}

export default AlertDetailOptionStatus
