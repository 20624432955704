import { format } from "date-fns"
import { es } from "date-fns/locale"


  /*

  Return Formated Date 

  @param {Date} Date - Date object to transform his format
  @param {String} formatString - Format of the new Date returned
  @return {Date} Date object with the format specified in formatString


  */

export const formatDate = (date: Date | string, formatString: string = "dd MMMM yyyy") => {
  try {
    let dateTemp: Date
    if (typeof date === "object") {
      dateTemp = date
    } else {
      const [year, month, day] = date.split("T")[0].split("-").map(Number)
      dateTemp = new Date(year, month - 1, day)
    }

    return format(dateTemp, formatString, { locale: es })
  } catch (error) {
    return "--"
  }
}

export const formatTime = (time: Date | string, formatString: string = "HH:mm") => {
  try {
    let dateTemp: Date
    if (typeof time === "object") {
      dateTemp = time
    } else {
      dateTemp = new Date(`2000-01-01T${time}`)
    }

    return format(dateTemp, formatString, { locale: es })
  } catch (error) {
    return "--"
  }
}

export const formatManualTime = (time: string) => {
  try {
    let dateTemp
    if (time.includes("T")) {
      dateTemp = time.split("T")
      dateTemp = dateTemp[1]
      dateTemp = dateTemp.split(":")
      return `${dateTemp[0]}:${dateTemp[1]}`
    } else {
      dateTemp = time.split(":")
      return `${dateTemp[0]}:${dateTemp[1]}`
    }

  } catch (error) {
    return "--"
  }
}

export const formatAdvanceDate = (date: any, formatString: string = "dd MMMM yyyy") => {
  try {
    // let dateTemp: Date
    // if (typeof date === "object") {
    //   dateTemp = date
    // } else {
    //   const purgeDate = date.split("T")
    //   const purgeHour = purgeDate[1].split(".")[0]
    //   const [year, month, day] = purgeDate[0].split("-").map(Number)
    //   const [hour, minute, second] = purgeHour.split(":").map(Number)      
    //   dateTemp = new Date(year, month - 1, day, hour, minute, second)
    // }

    return format(Date.parse(date), formatString, { locale: es })
  } catch (error) {
    throw error
  }
}
