import axiosClient from "config/axiosClient"
// import configEnv from "config/configEnv"
import { IResponseService } from "interfaces/IResponseService"
import { AxiosResponse } from "axios"

const axiosClientMiddleware = axiosClient("MIDDLEWARE")

export const updateShippingGroupStateCustom = async (
  body: any,
  // cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>("/execute/shippinggroup", body, {
    headers: { "Content-Type": "application/json" },
    // cancelToken,
  })

  return response
}

export const updateShippingGroupStateSelectPicking = async (
  body: any,
  // cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>("/bff/select_picking", body, {
    headers: { "Content-Type": "application/json" },
    // cancelToken,
  })

  return response
}

export const updateShippingGroupStateDeliveredInSourceCancel = async (
  body: any,
  // cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>("/bff/delivered_in_source_cancel", body, {
    headers: { "Content-Type": "application/json" },
    // cancelToken,
  })

  return response
}