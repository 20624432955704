import { useState, useContext, useCallback, useEffect } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { OderGroupingType } from "types/OrderType"
// import Logger from "classes/Logger"
// import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
// import useUpdateMultipleSgs from "overrides/theme/entel/base/hooks/v4/useUpdateMultipleSgs"

interface ICheckList {
  data: OderGroupingType
  request: any
}

const PREPICKING_ORDERS_VAR = "PREPICKING_ORDERS"

const UseCheckedPrePickingList = ({ data, request }: ICheckList) => {
  const [ordersSGChecked, setOrdersSGChecked] = useState<Array<string>>([])
  const [loadingUpdateSG, setLoadingUpdateSG] = useState(false)
  const { notification } = useContext(GlobalContext)
  // const { post_update_multiple_sgs_action } = useUpdateMultipleSgs()

  // const statusGroup = { action: "" }

  useEffect(() => {
    if (ordersSGChecked?.length) {
      localStorage.setItem(PREPICKING_ORDERS_VAR, JSON.stringify(ordersSGChecked))
      return
    }
  }, [ordersSGChecked])

  useEffect(() => {
    const prepicking_orders = localStorage.getItem(PREPICKING_ORDERS_VAR)
    setOrdersSGChecked(prepicking_orders ? JSON.parse(prepicking_orders) : [])
  }, [])

  const handle_sg_to_prepicking = useCallback(
    (ev: any) => {
      const { id, checked } = ev.target
      if (!checked) {
        setOrdersSGChecked((prevOrderSG) => {
          return prevOrderSG.filter((sg) => sg !== id)
        })
        return
      }
      setOrdersSGChecked((prevOrderSG) => [...prevOrderSG, id])
    },
    [setOrdersSGChecked],
  )

  const updateShippingGroupAction = async () => {
    setLoadingUpdateSG(true)
    localStorage.setItem(PREPICKING_ORDERS_VAR, JSON.stringify(ordersSGChecked))
    setTimeout(() => {
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "se actualizó correctamente los órdenes",
          title: "Actualización Órdenes",
          type: "success",
        },
      })
      request().then(() => setLoadingUpdateSG(false))
    }, 2000)
    // try {
    //   setLoadingUpdateSG(true)

    //   const resultUpdateOrders = await post_update_multiple_sgs_action({
    //     action: statusGroup.action,
    //     entities: ordersSGChecked?.map((sg: string) => ({ id: sg })),
    //   })

    //   setLoadingUpdateSG(false)

    //   if (resultUpdateOrders?.data?.code === TransactionCodeEnum.ok) {
    //     notification?.dispatch({
    //       type: "ADD_NOTIFICATION",
    //       payload: {
    //         message: "se actualizo correctamente los SG’s",
    //         title: "Actualización SG’s",
    //         type: "success",
    //       },
    //     })

    //     setTimeout(() => request(), 1200)
    //   }
    // } catch (error: any) {
    //   setLoadingUpdateSG(false)
    //   if (error.response?.status === 500) {
    //     notification?.dispatch({
    //       type: "ADD_NOTIFICATION",
    //       payload: {
    //         message: "verifique el estado de los SG’s",
    //         title: "Algo salió mal",
    //         type: "danger",
    //       },
    //     })
    //   } else {
    //     Logger.error(error)
    //     errorHander?.dispatch({ hasError: true, code: error.response?.status })
    //   }
    // }
  }

  return {
    sgs_checked_to_prepicking: ordersSGChecked,
    handle_sg_to_prepicking,
    loading_prepicking_action: loadingUpdateSG,
    apply_prepicking_action: updateShippingGroupAction,
  }
}

export default UseCheckedPrePickingList
