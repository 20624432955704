export const alertDetailOptionStatusStyle = `
  .alert_detail_opt_status__main {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    background: #FEA601;
    color: #363C49;
    font-weight: 500;
    border-radius: 0.5rem;
    text-align: justify;
    font-family: "Montserrat";
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .alert_detail_opt_status__main .icon {
    font-size: 1.8rem;
  }
  .alert_detail_opt {
    background: #BA1236;
    color: #FFF;
  }
  @keyframes pulse {
  0% {
    scale: 100%
  }
  50% {
    scale: 102%;
  }
  100% {
    scale: 100%;
  }
} 
`