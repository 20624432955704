export const adminSourceScreenStyle = `
  .admin_source_screen__wapper_container {
    width: 100%;
    display: grid;
    place-items: center;
    gap: 1rem;
  }
  .admin_source_screen__container {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 1rem;
  }
  .admin_source_screen__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 1rem;
  }
  .admin_source_screen__body .ass__body_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .dus__info_name {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1rem;
    color: #23203F;
  }
  .admin_source_screen__body .ass__body_search_cont {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0;
  }
  .admin_source_screen__body .ass__body_sources_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0;
  }
  .admin_source_screen__body__button_submit {
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 0.8rem;
    color: #fff;
    padding: 0 2rem;
    gap: 0 1em;
    background: #002eff;
    border-radius: 1.5rem;
  }
  .admin_source_screen__body__button_submit:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  .admin_source_screen__container_sources {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .admin_source_screen__container_list_sources {
    width: 100%;
    max-height: 500px;
    overflow: auto;
    padding-right: 1em;
  }
  .admin_source_screen__input_form {
    width: 100%;
    height: 2.5rem;
    background-color: #f3f5ff;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 1rem;
    border-radius: 1.5rem;
    padding: 0 2em;
  }
  .admin_source_screen__input_form:disabled {
    cursor: not-allowed;
  }
  .admin_source_screen__input_form:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px #f3f5ff inset;
  }
  .admin_source_screen__input_form::placeholder {
    color: #716F87;
  }
  .admin_source_screen__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    gap: 0.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    color: #716f87;
  }
  .admin_source_screen__footer .logo_omnix {
    width: 8rem;
  }
  @media screen and (min-width: 1024px) {
    .admin_source_screen__container {
      width: 90%;
    }
    .admin_source_screen__body {
      padding: 1rem 2rem;
    }
    .admin_source_screen__container_sources {
      width: 70%;
    }
    .admin_source_screen__input_form {
      width: 70%;
    }
  }
`
