import axiosClient from "config/axiosClient"
// import configEnv from "config/configEnv"
import { IResponseService } from "interfaces/IResponseService"
import { AxiosResponse, CancelToken } from "axios"

const axiosClientMiddleware = axiosClient("MIDDLEWARE")

export const authorizerService = async (
  body: any,
  // cancelToken: CancelToken,
): Promise<AxiosResponse<any>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>("/osm/security/oauth2/token", body, {
    headers: { "Content-Type": "application/json" },
    // cancelToken,
  })

  return response
}

export const post_middleware_query = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>("/query", body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const post_middleware_query_items = async (
  body: any,
  channel: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>(
    `orch/inventory/item/all/channel/${channel}?allProperties=true`,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken,
    },
  )

  return response
}

export const post_middleware_osrm = async (
  endpoint: string,
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>(`/osrm/${endpoint}`, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const post_middleware_olm = async (
  endpoint: string,
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>(`/olm/${endpoint}`, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const post_middleware_oim = async (
  endpoint: string,
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>(`/oim/${endpoint}`, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const put_middleware_oim = async (
  endpoint: string,
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.put<IResponseService<any>>(`/oim/${endpoint}`, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const post_middleware_update_multiple_sgs = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>(`/execute/multi`, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const post_middleware_state_machines_types = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientMiddleware.getClient()

  const response = await client.post<IResponseService<any>>(`/osmc/state-machines/types`, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}