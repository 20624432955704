import { Fragment, useContext, useEffect } from "react"
import logo_entel from "assets/images/logo_entel.svg"
import background_image_ship from "assets/images/background-image-ship.svg"
import shade_backg_ship from "assets/images/shade-backg-ship.svg"
import { loadingFormStyle } from "overrides/theme/entel/base/pages/LoadScreen/LoadScreen.style"
import Helmet from "components/Commons/Helmet/Helmet"
import { AuthContext } from "context/context"
import { useHistory } from "react-router-dom"
import JsonEngine from "classes/JsonEngine"
import { useAuth0 } from "@auth0/auth0-react"

const LogoutScreen = () => {
  const {
    dispatch,
    state: { user },
  } = useContext(AuthContext)
  const history = useHistory()
  const { logout } = useAuth0()

  useEffect(() => {
    if (!user) {
      history.push("/")
      console.log("Entro")
    } else {
      logout().then(() => {
        dispatch({ type: "[auth] Logout" })
        JsonEngine.clear()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Fragment>
      <Helmet />
      <div className="cont-loading">
        <div className="cont-loading--sub__left">
          <div className="sub--left__body">
            <img src={logo_entel} alt="logo" className="client-logo" />
            <div className="info-loading">
              <h4>Cerrando sesión, espere...</h4>
            </div>
          </div>
        </div>
        <div className="cont-loading--sub__right">
          <img src={shade_backg_ship} alt="X" className="shade-bg" />
          <div className="sub--right__body">
            <img src={background_image_ship} alt="X" className="main-bg-image" />
            <p className="bg-title">Llega más rápido y haz feliz a tu cliente</p>
            <p className="bg-description">
              Desde nuestra plataforma gestiona pedidos, envíos, el inventario y almacenes.
            </p>
          </div>
        </div>
      </div>
      <style jsx>{loadingFormStyle}</style>
    </Fragment>
  )
}

export default LogoutScreen
